.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.reactEasyCrop_CropArea {
  width: 97px;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #333;
}
