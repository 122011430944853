.counterClockwiseSpin {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: counterClockwiseSpin;
  animation-timing-function: linear;
}
@keyframes counterClockwiseSpin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.clockwiseSpin {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: clockwiseSpin;
  animation-timing-function: linear;
}
@keyframes clockwiseSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
